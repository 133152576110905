/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
          }
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      limit: 9
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            slug
            title
            author
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
    const { posts } = data // data.markdownRemark holds your post data
    return (
        <Layout>
            <Seo />
            <div className="home-banner col-1">
                <img
                    src={"/assets/monster.webp"}
                    alt={"The Primeval Monster!"}
                    className="featured-image"
                />
                <a href="https://unsplash.com/photos/2pq78x1a18w" target="_blank" rel="noreferrer" title="View the original!" className="home-photo-attribution">Artist: David Brooke Martin</a>
                <div className="symbol-divider">⍟</div>
            </div>
            <BlogListHome data={posts} />
        </Layout>
    )
}

export default HomePage
